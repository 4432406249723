import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const $ = require("jquery");

export const onInitialClientRender = () => {
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/5bf4ef7b79ed6453ccaa5cc1/default";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();
};

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdLA-4ZAAAAAF-7rcCCpiMpjvch7Oj2oWU5tjd0">
      {element}
    </GoogleReCaptchaProvider>
  );
};

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-a-guide-to-seamless-ai-integration-into-your-existin-gsystems-js": () => import("./../../../src/pages/blog/a-guide-to-seamless-AI-integration-into-your-existin-gsystems.js" /* webpackChunkName: "component---src-pages-blog-a-guide-to-seamless-ai-integration-into-your-existin-gsystems-js" */),
  "component---src-pages-blog-embeddable-micro-apps-go-live-js": () => import("./../../../src/pages/blog/embeddable-micro-apps-go-live.js" /* webpackChunkName: "component---src-pages-blog-embeddable-micro-apps-go-live-js" */),
  "component---src-pages-blog-four-ways-data-science-is-changing-the-retail-industry-js": () => import("./../../../src/pages/blog/four-ways-data-science-is-changing-the-retail-industry.js" /* webpackChunkName: "component---src-pages-blog-four-ways-data-science-is-changing-the-retail-industry-js" */),
  "component---src-pages-blog-getting-started-as-a-ux-intern-js": () => import("./../../../src/pages/blog/getting-started-as-a-ux-intern.js" /* webpackChunkName: "component---src-pages-blog-getting-started-as-a-ux-intern-js" */),
  "component---src-pages-blog-i-tela-soft-levels-up-software-quality-with-istqb-platinum-partnership-js": () => import("./../../../src/pages/blog/iTelaSoft-levels-up-software-quality-with-ISTQB-Platinum-Partnership.js" /* webpackChunkName: "component---src-pages-blog-i-tela-soft-levels-up-software-quality-with-istqb-platinum-partnership-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-maximising-cloud-investment-a-guide-for-mid-size-companies-js": () => import("./../../../src/pages/blog/maximising-cloud-investment-a-guide-for-mid-size-companies.js" /* webpackChunkName: "component---src-pages-blog-maximising-cloud-investment-a-guide-for-mid-size-companies-js" */),
  "component---src-pages-blog-new-addition-to-salesforce-mvp-hall-of-fame-sets-global-milestone-js": () => import("./../../../src/pages/blog/New-Addition-to-Salesforce-MVP-Hall-of-Fame-Sets-Global-Milestone.js" /* webpackChunkName: "component---src-pages-blog-new-addition-to-salesforce-mvp-hall-of-fame-sets-global-milestone-js" */),
  "component---src-pages-blog-saving-treasures-in-drowning-flash-js": () => import("./../../../src/pages/blog/saving-treasures-in-drowning-flash.js" /* webpackChunkName: "component---src-pages-blog-saving-treasures-in-drowning-flash-js" */),
  "component---src-pages-blog-sms-bot-leveling-up-customer-service-js": () => import("./../../../src/pages/blog/sms-bot-leveling-up-customer-service.js" /* webpackChunkName: "component---src-pages-blog-sms-bot-leveling-up-customer-service-js" */),
  "component---src-pages-blog-testing-narrowband-iot-deployments-js": () => import("./../../../src/pages/blog/testing-narrowband-iot-deployments.js" /* webpackChunkName: "component---src-pages-blog-testing-narrowband-iot-deployments-js" */),
  "component---src-pages-blog-trusting-numbers-and-figures-the-value-of-data-analytics-js": () => import("./../../../src/pages/blog/trusting-numbers-and-figures-the-value-of-data-analytics.js" /* webpackChunkName: "component---src-pages-blog-trusting-numbers-and-figures-the-value-of-data-analytics-js" */),
  "component---src-pages-blog-why-businesses-should-invest-in-mobile-app-development-js": () => import("./../../../src/pages/blog/why-businesses-should-invest-in-mobile-app-development.js" /* webpackChunkName: "component---src-pages-blog-why-businesses-should-invest-in-mobile-app-development-js" */),
  "component---src-pages-blog-working-with-native-android-sdks-from-flutter-js": () => import("./../../../src/pages/blog/working-with-native-android-sdks-from-flutter.js" /* webpackChunkName: "component---src-pages-blog-working-with-native-android-sdks-from-flutter-js" */),
  "component---src-pages-careers-apply-js": () => import("./../../../src/pages/careers/apply.js" /* webpackChunkName: "component---src-pages-careers-apply-js" */),
  "component---src-pages-careers-careers-form-js": () => import("./../../../src/pages/careers/careersForm.js" /* webpackChunkName: "component---src-pages-careers-careers-form-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-index-old-backup-js": () => import("./../../../src/pages/careers/indexOldBackup.js" /* webpackChunkName: "component---src-pages-careers-index-old-backup-js" */),
  "component---src-pages-careers-internship-js": () => import("./../../../src/pages/careers/Internship.js" /* webpackChunkName: "component---src-pages-careers-internship-js" */),
  "component---src-pages-clients-index-js": () => import("./../../../src/pages/clients/index.js" /* webpackChunkName: "component---src-pages-clients-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noteworthy-empowering-the-next-generation-js": () => import("./../../../src/pages/noteworthy/empowering-the-next-generation.js" /* webpackChunkName: "component---src-pages-noteworthy-empowering-the-next-generation-js" */),
  "component---src-pages-noteworthy-former-navantis-ceo-gives-major-impetus-to-itelasoft-js": () => import("./../../../src/pages/noteworthy/former-navantis-ceo-gives-major-impetus-to-itelasoft.js" /* webpackChunkName: "component---src-pages-noteworthy-former-navantis-ceo-gives-major-impetus-to-itelasoft-js" */),
  "component---src-pages-noteworthy-how-migrating-to-aws-eliminated-tech-worries-at-skwirk-js": () => import("./../../../src/pages/noteworthy/How-migrating-to-AWS-eliminated-tech-worries-at-Skwirk.js" /* webpackChunkName: "component---src-pages-noteworthy-how-migrating-to-aws-eliminated-tech-worries-at-skwirk-js" */),
  "component---src-pages-noteworthy-i-tela-soft-announces-the-release-of-5-g-capabilities-on-its-xg-platform-js": () => import("./../../../src/pages/noteworthy/iTelaSoft-announces-the-release-of-5G-capabilities-on-its-XG-platform.js" /* webpackChunkName: "component---src-pages-noteworthy-i-tela-soft-announces-the-release-of-5-g-capabilities-on-its-xg-platform-js" */),
  "component---src-pages-noteworthy-i-tela-soft-ceo-honored-at-the-iesl-nsw-awards-js": () => import("./../../../src/pages/noteworthy/iTelaSoft-CEO-Honored-at-the-IESL-NSW-Awards.js" /* webpackChunkName: "component---src-pages-noteworthy-i-tela-soft-ceo-honored-at-the-iesl-nsw-awards-js" */),
  "component---src-pages-noteworthy-i-tela-soft-joins-fintech-australia-as-an-ecosystem-partner-js": () => import("./../../../src/pages/noteworthy/iTelaSoft-joins-Fintech-Australia-as-an-ecosystem-partner.js" /* webpackChunkName: "component---src-pages-noteworthy-i-tela-soft-joins-fintech-australia-as-an-ecosystem-partner-js" */),
  "component---src-pages-noteworthy-i-tela-soft-levels-up-software-quality-with-istqb-platinum-partnership-js": () => import("./../../../src/pages/noteworthy/iTelaSoft-levels-up-software-quality-with-ISTQB-Platinum-Partnership.js" /* webpackChunkName: "component---src-pages-noteworthy-i-tela-soft-levels-up-software-quality-with-istqb-platinum-partnership-js" */),
  "component---src-pages-noteworthy-index-js": () => import("./../../../src/pages/noteworthy/index.js" /* webpackChunkName: "component---src-pages-noteworthy-index-js" */),
  "component---src-pages-noteworthy-itelasoft-night-2018-js": () => import("./../../../src/pages/noteworthy/itelasoft-night-2018.js" /* webpackChunkName: "component---src-pages-noteworthy-itelasoft-night-2018-js" */),
  "component---src-pages-noteworthy-itelasoft-night-2019-js": () => import("./../../../src/pages/noteworthy/itelasoft-night-2019.js" /* webpackChunkName: "component---src-pages-noteworthy-itelasoft-night-2019-js" */),
  "component---src-pages-noteworthy-itelasoft-partners-mobitel-on-iot-devices-js": () => import("./../../../src/pages/noteworthy/itelasoft-partners-mobitel-on-iot-devices.js" /* webpackChunkName: "component---src-pages-noteworthy-itelasoft-partners-mobitel-on-iot-devices-js" */),
  "component---src-pages-noteworthy-new-addition-to-salesforce-mvp-hall-of-fame-sets-global-milestone-js": () => import("./../../../src/pages/noteworthy/New-Addition-to-Salesforce-MVP-Hall-of-Fame-Sets-Global-Milestone.js" /* webpackChunkName: "component---src-pages-noteworthy-new-addition-to-salesforce-mvp-hall-of-fame-sets-global-milestone-js" */),
  "component---src-pages-noteworthy-new-it-platform-initiative-from-itelasoft-js": () => import("./../../../src/pages/noteworthy/new-it-platform-initiative-from-itelasoft.js" /* webpackChunkName: "component---src-pages-noteworthy-new-it-platform-initiative-from-itelasoft-js" */),
  "component---src-pages-noteworthy-revolutionizing-the-coconut-industry-through-digital-emp-js": () => import("./../../../src/pages/noteworthy/revolutionizing-the-coconut-industry-through-digital-EMP.js" /* webpackChunkName: "component---src-pages-noteworthy-revolutionizing-the-coconut-industry-through-digital-emp-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-partners-modals-modal-aws-js": () => import("./../../../src/pages/partners/modals/ModalAws.js" /* webpackChunkName: "component---src-pages-partners-modals-modal-aws-js" */),
  "component---src-pages-partners-modals-modal-bizagi-js": () => import("./../../../src/pages/partners/modals/ModalBizagi.js" /* webpackChunkName: "component---src-pages-partners-modals-modal-bizagi-js" */),
  "component---src-pages-partners-modals-modal-fin-tech-js": () => import("./../../../src/pages/partners/modals/ModalFinTech.js" /* webpackChunkName: "component---src-pages-partners-modals-modal-fin-tech-js" */),
  "component---src-pages-partners-modals-modal-ibm-js": () => import("./../../../src/pages/partners/modals/ModalIBM.js" /* webpackChunkName: "component---src-pages-partners-modals-modal-ibm-js" */),
  "component---src-pages-partners-modals-modal-istqb-js": () => import("./../../../src/pages/partners/modals/ModalISTQB.js" /* webpackChunkName: "component---src-pages-partners-modals-modal-istqb-js" */),
  "component---src-pages-partners-modals-modal-mongo-db-js": () => import("./../../../src/pages/partners/modals/ModalMongoDB.js" /* webpackChunkName: "component---src-pages-partners-modals-modal-mongo-db-js" */),
  "component---src-pages-partners-modals-modal-power-js": () => import("./../../../src/pages/partners/modals/ModalPower.js" /* webpackChunkName: "component---src-pages-partners-modals-modal-power-js" */),
  "component---src-pages-partners-modals-modal-saleforce-js": () => import("./../../../src/pages/partners/modals/ModalSaleforce.js" /* webpackChunkName: "component---src-pages-partners-modals-modal-saleforce-js" */),
  "component---src-pages-partners-modals-modal-symbox-js": () => import("./../../../src/pages/partners/modals/ModalSymbox.js" /* webpackChunkName: "component---src-pages-partners-modals-modal-symbox-js" */),
  "component---src-pages-partners-modals-modal-wso-js": () => import("./../../../src/pages/partners/modals/ModalWso.js" /* webpackChunkName: "component---src-pages-partners-modals-modal-wso-js" */),
  "component---src-pages-partners-partner-cards-aws-js": () => import("./../../../src/pages/partners/partnerCards/aws.js" /* webpackChunkName: "component---src-pages-partners-partner-cards-aws-js" */),
  "component---src-pages-partners-partner-cards-bizagi-js": () => import("./../../../src/pages/partners/partnerCards/bizagi.js" /* webpackChunkName: "component---src-pages-partners-partner-cards-bizagi-js" */),
  "component---src-pages-partners-partner-cards-fintechaustralia-js": () => import("./../../../src/pages/partners/partnerCards/fintechaustralia.js" /* webpackChunkName: "component---src-pages-partners-partner-cards-fintechaustralia-js" */),
  "component---src-pages-partners-partner-cards-ibm-js": () => import("./../../../src/pages/partners/partnerCards/ibm.js" /* webpackChunkName: "component---src-pages-partners-partner-cards-ibm-js" */),
  "component---src-pages-partners-partner-cards-istqb-js": () => import("./../../../src/pages/partners/partnerCards/istqb.js" /* webpackChunkName: "component---src-pages-partners-partner-cards-istqb-js" */),
  "component---src-pages-partners-partner-cards-mongodb-js": () => import("./../../../src/pages/partners/partnerCards/mongodb.js" /* webpackChunkName: "component---src-pages-partners-partner-cards-mongodb-js" */),
  "component---src-pages-partners-partner-cards-powerapp-js": () => import("./../../../src/pages/partners/partnerCards/powerapp.js" /* webpackChunkName: "component---src-pages-partners-partner-cards-powerapp-js" */),
  "component---src-pages-partners-partner-cards-salesforce-js": () => import("./../../../src/pages/partners/partnerCards/salesforce.js" /* webpackChunkName: "component---src-pages-partners-partner-cards-salesforce-js" */),
  "component---src-pages-partners-partner-cards-symbox-js": () => import("./../../../src/pages/partners/partnerCards/symbox.js" /* webpackChunkName: "component---src-pages-partners-partner-cards-symbox-js" */),
  "component---src-pages-partners-partner-cards-wso-js": () => import("./../../../src/pages/partners/partnerCards/wso.js" /* webpackChunkName: "component---src-pages-partners-partner-cards-wso-js" */),
  "component---src-pages-products-xg-automation-platform-js": () => import("./../../../src/pages/products/xg-automation-platform.js" /* webpackChunkName: "component---src-pages-products-xg-automation-platform-js" */),
  "component---src-pages-resources-promotion-index-js": () => import("./../../../src/pages/Resources-Promotion/index.js" /* webpackChunkName: "component---src-pages-resources-promotion-index-js" */),
  "component---src-pages-services-build-engineer-ai-js": () => import("./../../../src/pages/services/build-engineer/ai.js" /* webpackChunkName: "component---src-pages-services-build-engineer-ai-js" */),
  "component---src-pages-services-build-engineer-aws-js": () => import("./../../../src/pages/services/build-engineer/aws.js" /* webpackChunkName: "component---src-pages-services-build-engineer-aws-js" */),
  "component---src-pages-services-build-engineer-case-study-ai-lead-distribution-js": () => import("./../../../src/pages/services/build-engineer/case-study-AI-lead-distribution.js" /* webpackChunkName: "component---src-pages-services-build-engineer-case-study-ai-lead-distribution-js" */),
  "component---src-pages-services-build-engineer-compliance-services-js": () => import("./../../../src/pages/services/build-engineer/compliance-services.js" /* webpackChunkName: "component---src-pages-services-build-engineer-compliance-services-js" */),
  "component---src-pages-services-build-engineer-data-analytics-ai-js": () => import("./../../../src/pages/services/build-engineer/data-analytics-ai.js" /* webpackChunkName: "component---src-pages-services-build-engineer-data-analytics-ai-js" */),
  "component---src-pages-services-build-engineer-devops-js": () => import("./../../../src/pages/services/build-engineer/devops.js" /* webpackChunkName: "component---src-pages-services-build-engineer-devops-js" */),
  "component---src-pages-services-build-engineer-digital-transformation-js": () => import("./../../../src/pages/services/build-engineer/digital-transformation.js" /* webpackChunkName: "component---src-pages-services-build-engineer-digital-transformation-js" */),
  "component---src-pages-services-build-engineer-fintech-js": () => import("./../../../src/pages/services/build-engineer/fintech.js" /* webpackChunkName: "component---src-pages-services-build-engineer-fintech-js" */),
  "component---src-pages-services-build-engineer-product-development-js": () => import("./../../../src/pages/services/build-engineer/product-development.js" /* webpackChunkName: "component---src-pages-services-build-engineer-product-development-js" */),
  "component---src-pages-services-build-engineer-quality-assurance-js": () => import("./../../../src/pages/services/build-engineer/quality-assurance.js" /* webpackChunkName: "component---src-pages-services-build-engineer-quality-assurance-js" */),
  "component---src-pages-services-build-engineer-quality-assurance-old-js": () => import("./../../../src/pages/services/build-engineer/quality-assurance-old.js" /* webpackChunkName: "component---src-pages-services-build-engineer-quality-assurance-old-js" */),
  "component---src-pages-services-build-engineer-salesforce-js": () => import("./../../../src/pages/services/build-engineer/salesforce.js" /* webpackChunkName: "component---src-pages-services-build-engineer-salesforce-js" */),
  "component---src-pages-services-build-engineer-salesforce-old-js": () => import("./../../../src/pages/services/build-engineer/salesforce-old.js" /* webpackChunkName: "component---src-pages-services-build-engineer-salesforce-old-js" */),
  "component---src-pages-services-build-engineer-software-development-js": () => import("./../../../src/pages/services/build-engineer/software-development.js" /* webpackChunkName: "component---src-pages-services-build-engineer-software-development-js" */),
  "component---src-pages-services-build-engineer-wso-2-services-js": () => import("./../../../src/pages/services/build-engineer/wso2-services.js" /* webpackChunkName: "component---src-pages-services-build-engineer-wso-2-services-js" */),
  "component---src-pages-services-cloud-services-js": () => import("./../../../src/pages/services/cloud-services.js" /* webpackChunkName: "component---src-pages-services-cloud-services-js" */),
  "component---src-pages-services-data-science-js": () => import("./../../../src/pages/services/data-science.js" /* webpackChunkName: "component---src-pages-services-data-science-js" */),
  "component---src-pages-services-database-services-js": () => import("./../../../src/pages/services/database-services.js" /* webpackChunkName: "component---src-pages-services-database-services-js" */),
  "component---src-pages-services-enterprise-integration-js": () => import("./../../../src/pages/services/enterprise-integration.js" /* webpackChunkName: "component---src-pages-services-enterprise-integration-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-iot-and-devices-js": () => import("./../../../src/pages/services/iot-and-devices.js" /* webpackChunkName: "component---src-pages-services-iot-and-devices-js" */),
  "component---src-pages-services-managed-services-js": () => import("./../../../src/pages/services/managed-services.js" /* webpackChunkName: "component---src-pages-services-managed-services-js" */),
  "component---src-pages-services-managed-services-resources-resources-js": () => import("./../../../src/pages/services/managed-services-resources/resources.js" /* webpackChunkName: "component---src-pages-services-managed-services-resources-resources-js" */),
  "component---src-pages-services-managed-services-resources-support-maintenance-js": () => import("./../../../src/pages/services/managed-services-resources/support-maintenance.js" /* webpackChunkName: "component---src-pages-services-managed-services-resources-support-maintenance-js" */),
  "component---src-pages-services-mobile-solutions-js": () => import("./../../../src/pages/services/mobile-solutions.js" /* webpackChunkName: "component---src-pages-services-mobile-solutions-js" */),
  "component---src-pages-services-product-innovation-js": () => import("./../../../src/pages/services/product-innovation.js" /* webpackChunkName: "component---src-pages-services-product-innovation-js" */),
  "component---src-pages-services-strategy-design-poc-mvp-js": () => import("./../../../src/pages/services/strategy-design/poc-mvp.js" /* webpackChunkName: "component---src-pages-services-strategy-design-poc-mvp-js" */),
  "component---src-pages-services-strategy-design-ux-js": () => import("./../../../src/pages/services/strategy-design/ux.js" /* webpackChunkName: "component---src-pages-services-strategy-design-ux-js" */),
  "component---src-pages-services-strategy-design-ux-technical-design-js": () => import("./../../../src/pages/services/strategy-design/ux-technical-design.js" /* webpackChunkName: "component---src-pages-services-strategy-design-ux-technical-design-js" */),
  "component---src-pages-testimonials-index-js": () => import("./../../../src/pages/testimonials/index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */)
}

